import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import ios from "../../../assets/app-store-badge.svg";
import google from "../../../assets/Google_Play-Badge-Logo.wine.svg";
import { HeaderBorder } from "../../OCVFeatures/PossibilityBox/PossibilityBox";
import { NominateButton } from "../../OCVFeatures/MonthlyFeature/MonthlyFeature";

interface Props {
  bgColor?: string;
  textColor?: string;
  fontFam?: string;
  buttonColor?: string;
  buttonText?: string;
}

interface ButtonProps {
  buttonBgColor: string;
  buttonTextColor: string;
}

export default function DownloadOurApp(props: any) {
  const config = props.viewData?.config;
  let backgroundImageConfig = undefined;

  const appID = process.env.REACT_APP_APPID;

  if (config?.rgba1 && config?.rgba2 && config?.backgroundImageURL)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2}), url(${config.backgroundImageURL})`;
  else if (config?.rgba1 && config?.rgba2)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2})`;
  else if (config?.backgroundImageURL)
    backgroundImageConfig = `url(${config.backgroundImageURL})`;

  return (
    <OuterWrapper
      as='section'
      bgColor={config?.bgColor}
      textColor={config?.textColor}
    >
      <InnerWrapper as='div'>
        <HeaderWrapper as='div'>
          <HeadingText as='h2' $fontFam={config?.font}>
            {props.viewData?.title}
          </HeadingText>
          <HeaderBorder $divideColor={config?.divideColor} />
        </HeaderWrapper>
        <GridContainer as='div'>
          <FlexContainer as='div'>
            <ImgMockup
              as='img'
              src={props.viewData?.mockup}
              alt={props?.viewData?.config?.alt}
            />
          </FlexContainer>
          <Flex as='div'>
            <TextDesc as='p'>{props.viewData.config?.description}</TextDesc>
            <NominateButton
              buttonBgColor={config?.buttonBgColor}
              buttonTextColor={config?.buttonTextColor}
              to={config?.buttonLink}
            >
              {config?.buttonText}
            </NominateButton>
            <GridContainer as='div'>
              <FlexContainer as='div'>
                <a
                  href={props.viewData.config?.iosLink}
                  rel='noopener noreferrer'
                  target={"_blank"}
                >
                  <ImgDownloadApp
                    as='img'
                    alt='Click to view our app in the apple store'
                    src={ios}
                  />
                </a>
                <a
                  href={props.viewData.config?.androidLink}
                  rel='noopener noreferrer'
                  target={"_blank"}
                >
                  <ImgDownloadApp
                    as='img'
                    alt='Click to view our app in the google play store'
                    src={google}
                  />
                </a>
              </FlexContainer>
              <FlexContainer as='div' data-hidden='true'>
                <ImgQRCode
                  as='img'
                  alt='QR Code Download App Now'
                  src={`https://s3.amazonaws.com/myocv/ocvapps/${appID}/qrcode/${appID}.png`}
                />
                <CaptionQRCode as='p'>DOWNLOAD NOW</CaptionQRCode>
              </FlexContainer>
            </GridContainer>
          </Flex>
        </GridContainer>
      </InnerWrapper>
    </OuterWrapper>
  );
}

export const OuterWrapper = styled.section<Props>`
  box-sizing: border-box;
  padding: 1.5rem;
  font-family: "Roboto", sans-serif;
  font-size: 2.25rem;
  line-height: 2.5rem;
  margin-bottom: 12px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};
`;
export const InnerWrapper = styled.div`
  overflow: hidden;
  margin: 0 auto;

  @media (min-width: 1024px) {
    max-width: 80rem;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeadingText = styled.h2`
  color: ${({ textColor }) => textColor};
  font-family: ${({ fontFam }) => fontFam};
  font-weight: 800;
  font-size: 2.25rem;
  line-height: 2.5rem;
  letter-spacing: -0.025em;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const ImgMockup = styled.img`
  height: auto;
  width: 50%;
  display: block;
  padding-top: 2rem;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  overflow: hidden;
  height: auto;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    &[data-hidden="true"] {
      display: none;
    }
  }
`;
export const Flex = styled(FlexContainer)`
  justify-content: space-around;
  margin-bottom: 15px;
`;

export const TextDesc = styled.p`
  text-transform: uppercase;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding: 2rem;
  font-weight: 600;
`;

export const ImgQRCode = styled.img`
  width: 9rem;
`;

export const CaptionQRCode = styled.p`
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1rem;
  font-family: inherit;
`;
export const ImgDownloadApp = styled.img`
  width: 9rem;
  padding-top: 1rem;
`;
